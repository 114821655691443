<template>
    <form @submit.prevent="submitHandler">
        <recess-modal
            variant="variant2"
            v-if="student"
            :show-modal="isModalVisible"
            @close="$emit('close')"
        >
            <template slot="modal-title">
                <h3 class="qa-educational-fulfillment-pop-up-title">
                    {{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.Title }}
                </h3>
            </template>
            <template slot="modal-body">
                <div class="row mt-5">
                    <div class="col-6">
                        <h5 class="mb-3">
                            {{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CourseProgress.Title }}
                        </h5> 

                        <div class="d-flex flex-wrap align-items-center justify-content-between"> 

                            <recess-numeric-input 
                                v-model="student.progressPercentage"
                                :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CourseProgress.Label" 
                                class="qa-educational-fulfillment-percentage w-50" 
                                :value-range-prop="{ min: 0, max: 100 }"
                                :currency-prop="{ suffix: ' %' }"
                                :allow-negative-prop="true"
                                :precision-prop="0"
                            />
                            
                            <recess-checkbox-input
                                v-model="student.completionAtRisk"
                                :default-checked="student.completionAtRisk"
                                name="completionAtRisk"
                                :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CompletionRisk"
                                class="w-40 s-simulate-input-holder qa-educational-fulfillment-completionAtRisk-checkbox"
                            />
                        </div>
                    </div>

                    <div class="col-6">
                        <h5 class="mb-3">
                            {{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CourseCompletedStatus.Title }}
                        </h5>

                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <recess-checkbox-input
                                v-model="student.completed"
                                :default-checked="student.completed"
                                name="completed"
                                :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CourseCompletedStatus.Label"
                                class="s-simulate-input-holder qa-educational-fulfillment-list-completed-checkbox"
                                @input="(newValue) => courseCompletedStatusHandler(newValue)"
                            />
                            
                            <div class="w-50">
                                <recess-date-picker
                                    v-model="student.dateCompleted"
                                    :default-value="student.dateCompleted"
                                    :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Dates.DateCompleted.Label"                                
                                    :disabled="!student.completed"
                                    class="qa-educational-fulfillment-list-date-completed"
                                    @input="$v.student.dateCompleted.$touch()" 
                                /> 
                            </div>
                        </div>
                        <span class="p-0 u-error-text qa-educational-fulfillment__date-picker-error-text">{{dateCompletedError}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <recess-divider show-line class="py-3" />
                    </div>
                </div>

                <div class="row"> 
                    <div class="col-12">
                        <h5 class="mb-3">{{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.Title }}</h5>
                    </div>
                    <div class="d-flex col-12">
                        <recess-multi-select
                            :selected-options="student.examStatus"
                            :options="examStatusOptions"
                            :single-select="true"
                            :select-options-text="examStatusOptions && examStatusOptions[0].displayText"
                            :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.ExamStatus.Label"                            
                            class="w-30 qa-educational-fulfillment-list-exam-status"
                            @input="(newValue) => examStatusHandler(newValue)"
                        />

                        <recess-date-picker
                            v-model="student.dateDiplomaExpires"
                            :default-value="student.dateDiplomaExpires"
                            :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Dates.DateDiplomaExpires.Label"
                            :error-message="dateDiplomaExpiresError"
                            :disabled="student.examStatus === PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.StatusNotApplicable || !student.examStatus"
                            class="w-20 ml-3 qa-educational-fulfillment-list-date-diploma-expires"
                            @input="$v.student.dateDiplomaExpires.$touch()"
                        />                        

                        <recess-file-upload
                            :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Upload.Placeholder"
                            :placeholder-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Upload.Placeholder"
                            max-size-file="10000000"
                            :max-file-to-upload="1"
                            :allowed-extensions="['png', 'pdf', 'tiff', 'jpg', 'JPEG']"
                            class="w-50 ml-3 qa-educational-fulfillment-upload-diploma"
                            @upload="(newValue) => uploadDiploma(newValue)"
                        />
                    </div>

                    <div class="col-12 mt-3" v-if="isDiplomaUploaded">
                        <recess-card variant="variant1" class="d-flex justify-content-between align-items-center">
                            <h5 class="m-0">{{ student.diplomaName }}</h5> 
                            
                            <div class="d-flex align-items-center justify-content-end ml-5 w-50"> 
                                <recess-button 
                                    title="Download diploma" 
                                    variant="secondary" 
                                    class="qa-download-diploma"
                                    @click.native.prevent="downloadDiploma"
                                /> 
                                
                                <span 
                                    class="ml-4 c-icon c-icon__bin qa-remove-diplomaFile" 
                                    @click="deleteDiploma()">
                                </span> 
                            </div>
                        </recess-card>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <recess-divider show-line class="py-3" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-3">{{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.PermanentEducationPoints.Title }}</h5> 
                    </div>
                    
                    <div class="col-2"> 
                        <recess-numeric-input
                            :value="student.permanentEducationPoints"
                            :currency-prop="null"
                            :precision-prop="0"
                            :label-text="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.PermanentEducationPoints.Label"
                            name="permanentEducationPoints"
                            class="qa-educational-fulfillment-list-permanent-education-points" 
                            @blur="$v.student.permanentEducationPoints.$touch()"
                            :error-message="permanentEducationPointsError" 
                            @input="(newValue) => setPermanentEducationPoints(newValue)"
                        /> 
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <recess-divider show-line class="py-3" />
                    </div>
                </div>

            </template>

            <template slot="modal-footer">
                <div class="row mb-5">
                    <div class="col-12 d-flex justify-content-end">
                        <recess-button
                            variant="secondary"
                            type="submit"
                            :title="PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.EditStudentStatusButton"
                            :disabled="!isValidOnSumbit"
                            class="qa-edit-fulfillment-submit-button mb-2"
                        />
                    </div>
                </div>

                <!-- show name, course name & start moment id of a list of selected students -->
                <!-- multipleSelectedStudents: if the list is bigger, remove margin to becuase of the scroll width -->
                <div :class="['row mb-0', { 'mr-0': multipleSelectedStudents }]">
                    <div class="col-3">
                        <h5>{{ setStudentLabel }}</h5>
                    </div>

                    <div class="col-5">
                        <h5>{{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Product.Label }}</h5>
                    </div>

                    <div class="col-4">
                        <h5>
                            {{ PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.StartmomentIdAndDate.Label }}
                        </h5>
                    </div>
                </div>

                <!-- show list of students in custom table styling -->
                <div v-if="isEditGroup" class="row my-0 u-border-line-top-bottom u-scroll-vertical">
                    <div
                        v-for="(selectedStudent, index) in selectedStudents"
                        :key="`selected-student-${index}`"
                        class="col-12"
                    >
                        <div class="row align-items-baseline">
                            <div class="col-3">
                                <recess-checkbox-input
                                    v-model="selectedStudent.isChecked"
                                    name="select-one-student-fulfillment-modal"
                                    :label-text="selectedStudent.studentName || '-'"
                                    :default-checked="selectedStudent.isChecked"
                                    :class="`qa-fulfillment-selected-student-name-modal-${index}`"
                                    variant="secondary"
                                />
                            </div>

                            <div class="col-5">
                                <span
                                    :class="`u-line-height-unset qa-fulfillment-selected-student-product-name-${index}`"
                                >
                                    {{ selectedStudent.productName || '-' }}
                                </span>
                            </div>

                            <div class="col-4">
                                <div
                                    :class="`qa-fulfillment-selected-student-start-moment-id-${index}`"
                                >
                                    {{ selectedStudent.startMomentId || '-' }}
                                </div>
                                <time
                                    :class="`qa-fulfillment-selected-student-start-moment-start-date-${index}`"
                                    >{{
                                        formatDateFromServer(selectedStudent.startMomentDate) || '-'
                                    }}</time
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <!-- show single student name, course name & startmoment Id -->
                <div
                    v-else
                    class="row align-items-baseline u-border-line-top-bottom my-0 py-3"
                >
                    <div class="col-3">
                        <p class="m-0 qa-educational-fulfillment-student-name">
                            {{ studentName || '-' }}
                        </p>
                    </div>

                    <div class="col-5">
                        <p class="m-0 qa-educational-fulfillment-product-name">
                            {{ student.productName || '-' }}
                        </p>
                    </div>
                    <div class="col-4">
                        <span class="d-flex qa-educational-fulfillment-start-moment-id">
                            {{ student.startMomentId || '-' }}
                        </span>
                        <time class="qa-educational-fulfillment-start-moment-start-date">{{
                            formatDateFromServer(student.startDate) || '-'
                        }}</time>
                    </div>
                </div>
            </template>
        </recess-modal>
    </form>
</template>

<script>
import dayjs from 'dayjs' 

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

import { validationMixin } from 'vuelidate'

import getExamStatusOptionsMixin from '@/mixins/getExamStatusOptionsMixin'
import { patchItem, getItemById } from '@/../../shared/api/SharedClient'

import { formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'
import { isValidDate, isValidPermanentEducationPoints } from '@/utils/validationHelpers'
import { isInvalidErrorMessage, hasError, educationalFulfillmentDateInvalid, defaultErrorMessage, studentSuccessAction } from '@/../../shared/constants/validationMessageHelper'

import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, WAIT_TIME, ERROR_MESSAGES } from '@/../../shared/constants/constantsGeneral.json'

export default {
    name: 'EditEducationalFulfillmentPopupComponent',
    mixins: [validationMixin, getExamStatusOptionsMixin],
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        student: {
            permanentEducationPoints: { isValidPermanentEducationPoints },
            dateCompleted: {
                isValidDate,
                minValue(value, student) {
                    // for editing students in group, check if any of startMomentDates in list of students is bigger than dateCompleted
                    if (this.isEditGroup) {
                        const isAnyStartMomentDatesBiggerThanEndDate =
                            this.selectedStudentsToBePatched.some((selectedStudent) => {
                                const startDate = dayjs(
                                    selectedStudent.startMomentDate,
                                    'YYYY-MM-DD'
                                )
                                const endDate = dayjs(student.dateCompleted, 'DD-MM-YYYY')
                                return startDate.isAfter(endDate)
                            })

                        if (isAnyStartMomentDatesBiggerThanEndDate) return false
                    }

                    // if course has not been completed allow update
                    if (!this.student.completed) return true

                    // for editing single student, check student's startDate is bigger than dateCompleted
                    if (this.student.startDate) {
                        const startDate = dayjs(this.student.startDate, 'YYYY-MM-DD')
                        const endDate = dayjs(student.dateCompleted, 'DD-MM-YYYY')
                        return startDate.isSameOrBefore(endDate)
                    }

                    return true
                }
            },
            dateDiplomaExpires: { isValidDate }
        }
    },
    data() {
        return {
            PROVIDER,
            ERROR_MESSAGES,
            examStatusOptions: [
                {
                    value: "",
                    displayText: 'Selecteer een waarde'
                }
            ],
            timeout: null,
            studentName: null,
            student: null,
            originalStudent: null,
            dataToBePatched: [],
            selectedStudents: [],
            isEditGroup: false,
            isDiplomaUploaded: false
        }
    },
    computed: {
        multipleSelectedStudents() {
            return this.selectedStudents.length > 3
        },
        isValidOnSumbit() { 
            return JSON.stringify(this.originalStudent) !== JSON.stringify(this.student) && this.student.permanentEducationPoints !== null
        },
        selectedStudentsToBePatched() {
            return (
                this.selectedStudents &&
                this.selectedStudents.filter((selectedStudent) => selectedStudent.isChecked)
            )
        },
        countOfSelectedStudentsToBePatched() {
            return this.selectedStudentsToBePatched && this.selectedStudentsToBePatched.length
        },
        setStudentLabel() {
            const studentText =
                this.countOfSelectedStudentsToBePatched > 1
                    ? `${PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Student.Label}s`
                    : `${PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Student.Label}`
            const capitalizedStudentText =
                PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Student.Label.charAt(0).toUpperCase() +
                PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Student.Label.slice(1)
            return this.isEditGroup
                ? `${this.countOfSelectedStudentsToBePatched} ${studentText}`
                : `${capitalizedStudentText}`
        },
        permanentEducationPointsError() {
            return this.$v.student.permanentEducationPoints.$error &&
                !this.$v.student.permanentEducationPoints.isValidPermanentEducationPoints
                ? ERROR_MESSAGES.isValidPermanentEducationPoints
                : null
        },
        dateCompletedError() {
            if(this.student.completed === false) return
            
            return (
                isInvalidErrorMessage(
                    PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Dates.DateCompleted.Label,
                    this.$v.student.dateCompleted,
                    'isValidDate'
                ) ||
                hasError(
                    educationalFulfillmentDateInvalid,
                    this.$v.student.dateCompleted,
                    'minValue'
                )
            )
        },
        dateDiplomaExpiresError() {
            return isInvalidErrorMessage(
                PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Dates.DateDiplomaExpires.Label,
                this.$v.student.dateDiplomaExpires,
                'isValidDate'
            )
        }
    },
    watch: {
        isModalVisible: function watchPropsChange(newValue) {
            // reset data when modal is closed by watching the value
            // change of isModalvisible from its parent 'OrderList'
            if (newValue) {
                this.getExamStatusOptions()
            } else {
                this.resetData()
            }             
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$bus.on('educational-fulfillment-single-student-reference', this.setEducationalFulfillmentSingleStudentReference)
            this.$bus.on('educational-fulfillment-students-in-group-reference', this.setEducationalFulfillmentStudentsInGroupReference)
        })
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
        this.$bus.off('educational-fulfillment-single-student-reference', this.setEducationalFulfillmentSingleStudentReference)
        this.$bus.off('educational-fulfillment-students-in-group-reference', this.setEducationalFulfillmentStudentsInGroupReference)
    },
    methods: { 
        setPermanentEducationPoints(newValue) {
            if(!newValue) { 
                this.student.permanentEducationPoints = 0

                return
            }

            this.student.permanentEducationPoints = newValue 
        }, 
        downloadDiploma() { 
            const createLink = document.createElement('a')

            createLink.setAttribute('href', this.student.diplomaFile)
            createLink.setAttribute('download', this.student.diplomaName || 'data.json')
            createLink.style.display = 'none'

            document.body.appendChild(createLink)

            createLink.click()

            document.body.removeChild(createLink)
        },
        deleteDiploma() {
            this.student.diplomaFile = null
            this.student.diplomaName = null
            this.isDiplomaUploaded = false
        },
        uploadDiploma(file) {
            this.isDiplomaUploaded = false

            if (file.length > 0) {
                this.student.diplomaFile = file[0].base64FileString
                this.student.diplomaName = file[0].structure.name
            } else {
                this.student.diplomaFile = null
                this.student.diplomaName = null
            } 
        },  
        closeModal() {
            this.timeout = setTimeout(() => this.$emit('close'), WAIT_TIME.closeModal)
        },
        resetData() {
            this.$v.$reset()
            this.timeout = null
            this.studentName = null
            this.student = null
            this.originalStudent = null
            this.dataToBePatched = []
            this.selectedStudents = []
            this.examStatusOptions = [
                {
                    value: "",
                    displayText: 'Selecteer een waarde'
                }
            ]
            this.isEditGroup = false
        },
        getCurrentLocalDateTime() {
            return dayjs().format('DD-MM-YYYY')
        },
        formatDateFromServer(date) {
            return formatUtcDateTimeToLocalDate(date)
        },
        setEducationalFulfillmentSingleStudentReference(payload) {
            if (!payload) return
            this.getEducationalFulfillmentById(payload.educationalFulfillmentId)
            this.studentName = payload.studentName
        },
        setEducationalFulfillmentStudentsInGroupReference(payload) {
            if (!payload) return
            this.selectedStudents = payload.selectedStudents
            this.initializeStudent() 

            this.originalStudent = JSON.parse(JSON.stringify(this.student))
            this.isEditGroup = true 
        },
        initializeStudent() {
            this.student = {
                permanentEducationPoints: 0,
                completed: false,
                examStatus: '',
                dateCompleted: null,
                dateDiplomaExpires: null,
                completionAtRisk: null,
                progressPercentage: null,
                diplomaName: null,
                diplomaFile: null
            }
        },
        async getEducationalFulfillmentById(educationalFulfillmentId) {
            if (!educationalFulfillmentId) return

            try {
                const response = await getItemById(
                    process.env.VUE_APP_FULFILLMENT_API_URL,
                    API_CALL_URL_PATHS.educationalFulfillments,
                    educationalFulfillmentId,
                    null,
                    false
                )
                if (!response) return
                response.dateCompleted = formatUtcDateTimeToLocalDate(response.dateCompleted)
                response.dateDiplomaExpires = formatUtcDateTimeToLocalDate(
                    response.dateDiplomaExpires
                )
                this.student = response

                if(this.student?.diplomaFile) {
                    this.isDiplomaUploaded = this.student.diplomaFile.includes('https') 
                }

                // copy the initial state of student list, only patch educational fulfillment if value has changed
                this.originalStudent = JSON.parse(JSON.stringify(this.student))
            } catch (error) {
                console.error('Something went wrong while retrieving educational fulfillment details', error)
            }
        }, 
        courseCompletedStatusHandler(completedStatus) {
            if (completedStatus) {
                this.student.dateCompleted = this.getCurrentLocalDateTime()
                this.$v.student.dateCompleted.$touch()
            } else {
                this.student.dateCompleted = null
            }
        },
        examStatusHandler(examStatus) {
            this.student.examStatus = examStatus
        },
        getFirstWordOfPropertyName(key) {
            return key.split(/(?=[A-Z])/)[0]
        },
        setDataToBePatched(originalStudent, updatedStudent) {
            const propertiesToPatch = [
                'permanentEducationPoints',
                'completed',
                'dateCompleted',
                'examStatus',
                'dateDiplomaExpires',
                'diplomaName',
                'diplomaFile',
                'completionAtRisk',
                'progressPercentage'
            ]
            // for single student, we check if property value is updated by comparing wtih original array
            // if yes, we set data objects to be patched
            // for students in group, we patch all new value stored in 'student' object
            propertiesToPatch.forEach((key) => {
                let patchValue
                patchValue = updatedStudent[key]

                const firstWordOfPropertyName = this.getFirstWordOfPropertyName(key)
                if (firstWordOfPropertyName === 'date' && updatedStudent[key] === '') {
                    patchValue = null
                }
                if (firstWordOfPropertyName === 'date' && updatedStudent[key]) {
                    patchValue = dayjs(updatedStudent[key], 'DD-MM-YYYY').format('YYYY-MM-DD')
                }
                if ((!this.isEditGroup && originalStudent[key] !== updatedStudent[key]) || this.isEditGroup ) {
                    this.dataToBePatched.push({
                        value: patchValue,
                        path: key,
                        op: 'replace'
                    })
                }
            })
        },
        submitHandler() {
            this.$v.$touch()
            if (this.$v.$invalid) return
            
            // for editing single student, only allow patching if any values are being edited
            if (!this.isEditGroup && this.isValidOnSumbit) {
                this.setDataToBePatched(this.originalStudent, this.student)
                // student.id is actually edcuational fulfillment id of the student
                this.patchEducationalFulfillment(this.student.id, this.dataToBePatched)
            } else {
                // only set patch data object once for all properties, which will be used for patching for all students
                this.setDataToBePatched(null, this.student)
                this.selectedStudentsToBePatched.map((studentToBePatched) => {
                    return this.patchEducationalFulfillment(
                        studentToBePatched.educationalFulfillmentId,
                        this.dataToBePatched
                    )
                })
            }
            this.$emit('submit')
        },
        updateOriginalStudentAfterPatching(dataToBePatched, response) {
            // update the original copy of student list after patching
            if (!this.isEditGroup) {
                dataToBePatched.forEach((x) => {
                    const key = x.path
                    // check if first word of property name contains date, if yes, convert utc time to local datetime
                    const firstWordOfPropertyName = this.getFirstWordOfPropertyName(key)
                    if (firstWordOfPropertyName === 'date') {
                        this.originalStudent[key] = formatUtcDateTimeToLocalDate(response[key])
                    } else {
                        this.originalStudent[key] = response[key]
                    }
                })
            }
        },
        patchEducationalFulfillment(educationalFulfillmentId, dataToBePatched) {
            if (educationalFulfillmentId && dataToBePatched.length > 0) {
                patchItem(
                    process.env.VUE_APP_FULFILLMENT_API_URL,
                    API_CALL_URL_PATHS.educationalFulfillments,
                    educationalFulfillmentId,
                    dataToBePatched,
                    false
                )
                .then((response) => {

                    if (!response) return
                    this.updateOriginalStudentAfterPatching(dataToBePatched, response)

                    const toastNotification = {
                        type: 'success',
                        message: studentSuccessAction('bewerkt')
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    this.closeModal()
                })
                .catch((error) => {
                    
                    let toastNotification = {
                        type: 'error',
                        message:  toastNotification.message = defaultErrorMessage
                    }

                    this.dataToBePatched = []

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    console.error(
                        'Something went wrong while patching educational fulfillment',
                        error
                    )
                })
            }
        }
    }
}
</script>